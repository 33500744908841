import React, { useState } from 'react';
import "../../css/MainPageSali.css";

const MainPageSali = () => {
  const [hoveredId, setHoveredId] = useState(null);

  const pages = [
    { id: 1, name: 'Sala 5', path: '/Sala5' },
    { id: 2, name: 'Sala 9', path: '/Sala9' },
    { id: 3, name: 'Sala 16', path: '/Sala16' },
    { id: 4, name: 'Sala 33', path: '/Sala33' },
    { id: 5, name: 'Sala 34', path: '/Sala34' },
    { id: 6, name: 'Sala 76', path: '/Sala76' },
    { id: 7, name: 'Sala 102', path: '/Sala102' },
    { id: 8, name: 'Sala 130', path: '/Sala130' },
    { id: 9, name: 'Sala 162', path: '/Sala162' },
    { id: 10, name: 'Sala 165', path: '/Sala165' },
    { id: 11, name: 'Sala 166', path: '/Sala166' },
    { id: 12, name: 'Sala 167', path: '/Sala167' },
    { id: 13, name: 'Sala 168', path: '/Sala168' },
    { id: 14, name: 'Sala 169', path: '/Sala169' },
    { id: 15, name: 'Sala 249', path: '/Sala249' },
  ];

  const getRoomSection = (id) => {
    if (id <= 6) return 'Parter';
    if (id <= 14) return 'Etajul 1';
    return 'Etajul 2';
  };

  const getCardColor = (id) => {
    if (id <= 6) return 'card-blue';
    if (id <= 14) return 'card-green';
    return 'card-gold';
  };

  const getHoverText = (id) => {
    if (id <= 5) return 'Tribunalul Cluj';
    else if (id === 6) return 'Judecatoria Cluj-Napoca';
    else if (id === 7) return 'Judecatoria Cluj-Napoca/Tribunalul Specializat';
    else if (id === 11) return 'Tribunalul Cluj/Judecatoria Cluj-Napoca';
    else if (id === 15) return 'Tribunalul Specializat';
    else if (id <= 14) return 'Judecatoria Cluj-Napoca';
    return 'N/A'; // Rezultat implicit
  };

  return (
    <div className="main-container">
      <h2 className="main-title responsive-title">Alegeți sala de judecată</h2>
      
      <div className="sections-container">
        {[...new Set(pages.map(page => getRoomSection(page.id)))].map(section => (
          <div key={section} className="section-block">
            <h3 className="section-title">{section}</h3>
            <div className="cards-grid">
              {pages
                .filter(page => getRoomSection(page.id) === section)
                .map((page) => (
                  <a
                    key={page.id}
                    href={page.path}
                    className={`card-link ${getCardColor(page.id)} ${hoveredId === page.id ? 'hovered' : ''}`}
                    onMouseEnter={() => setHoveredId(page.id)}
                    onMouseLeave={() => setHoveredId(null)}
                  >
                    <div className="card-content">
                      <div className="room-number">{page.name}</div>
                      <div className="hover-text">{getHoverText(page.id)}</div>
                    </div>
                  </a>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainPageSali;