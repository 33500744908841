import React, { Component } from 'react';
import "../css/homePage.css";
import Carousel1 from '../components/Carousel/OldHeaderCarousel';
import LinksBar from '../components/links';
import InfoLista from '../components/InfoUtile/InfoUtileList';
import "core-js/modules/es6.promise";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/es6.array.from.js";
import "whatwg-fetch";
import "es6-map/implement";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import NewsSection from '../components/Carousel/TopHomePageNews';
const { detect } = require('detect-browser');
const browser = detect();

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = { showPopup: true }; // Popup initially visible
  }

  closePopup = () => {
    this.setState({ showPopup: false });
  };

  render() {
    return (
      <React.Fragment>
          {/* Popup Section */}
          {this.state.showPopup && (
          <div className="popup-overlay">
            <div className="popup-content">
              <button className="close-btn" onClick={this.closePopup}>
                &times;
              </button>
              <h2 className="popup-title">Informare</h2>
              <p>
                Stimaţi justiţiabili,
              </p>
              <p> </p>
              <p>
                Tribunalul Cluj pune la dispoziție o nouă funcţionalitate prin care afişează informaţiile de la uşa sălilor de judecată, în timp real. {" "}
              </p>
              <p> </p>
              <p>
              Vă rugăm accesaţi pagina din meniul principal <b>[<i>Sali Live</i>]</b> sau folosind linkul:  {" "}
                <a
                  href="http://www.tribunalulcluj.ro/salaLive"
                  target="_blank"
                  rel="noreferrer"
                >
                 <i>www.tribunalulcluj.ro/salaLive</i> 
                </a>
              </p>
            </div>
          </div>
        )}

        {/* Blurred Background Content */}
        <div className={this.state.showPopup ? "blurred-content" : ""}>
        
        <section>
          <Carousel1 ></Carousel1>
        </section>
        <section className="bg-light" >
          <div className="px-5">
            <div class="row">
              <div class="col-lg-8 col-md-12 col-sm-12">
                <div class="left_content"></div>
               
                <div id="idInfoUtile">
                  <InfoLista style={{fontSize:'14 px'}} browserName={browser.name} browserVersion={browser.version} />
                </div>

              </div>
              <div className="border-left pb-3 col-lg-4 col-md-12 col-sm-12 ">
                <aside class="right_content ">
                  <NewsSection></NewsSection>
                </aside>
              </div>
            </div>
          </div>
        </section>

        <section  id="idLinkuriUtile">
          <LinksBar />
        </section>
      </div>
      </React.Fragment >
    );
  }
}

export default HomePage;

