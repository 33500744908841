import React, { Component } from 'react';
import Title from '../../components/Header/HeaderTitlePage';
import Anunt from '../../components/anunt';
import axios from 'axios';
import List from '@mui/material/List';

class SectiaPenala extends Component {
  state = {
    folders: []
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);

    /* Request all Folders and subfolders */
    axios
      .get(`/getFiles`, {
        params: {
          folderName: "PDFs/Liste sedinte 2020/Sectia Penala"
        }
      })
      .then(res => {

        //Sort res.data (folder's name like: 24 martie 2020) alphabetically
        res.data.sort(function(a, b) 
        {
          var textA = a.toUpperCase();
          var textB = b.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        //foreach folder read all subfolders/files
        for (let i=0;i<res.data.length;i++) {
          let dataFolder = res.data[i];
          axios
            .get(`/getFiles`, {params: { folderName: "PDFs/Liste sedinte 2020/Sectia Penala/" + dataFolder }})
            .then(res2 => {
                if(res2)
                {
                  let object2;
                  let object3 = [];

                  /* Extract data field from pdf filename */
                  for (let m = 0; m < res2.data.length; m++) 
                  {
                    let indexStart = 0;
                    let indexStop = res2.data[m].indexOf('.pdf');
                    //let dataDoc = res2.data[m].substring(indexStart + 1, indexStop);
                    let titluDoc = res2.data[m].substring(0, indexStop);
                    object2 = Object.assign({ titlu: titluDoc, data: dataFolder, locatie: "Liste sedinte 2020/Sectia Penala/" + dataFolder + "/" + res2.data[m] });
                    object3.push(object2);
                  }
                const object = Object.assign({ folder: dataFolder, docs: object3 });
                this.setState({ folders: [...this.state.folders, object] });
                }
            })
            .catch(e => {
              console.log("Eroare la deschiderea fișierului", e);
            });
        }
      })
      
      .catch(e => {
        console.log("Eroare la deschiderea folderului", e);
      });
  }

  render() {
    return (
      <React.Fragment>
        <Title
          title="Liste de sedinte urgente - Sectia Penala"
          page="Sectia Penala"
          breadcrumbs={true}
        />

        <div className="my-5 container">
          {/* Create a List 
            * Add each folder and docs in List using component <Anunt/>
            */}
          <List component="nav">
            {this.state.folders.map((item, index) => {
              return (
                <Anunt
                  key={index}
                  icon={require("../../images/icons/law2.png")}
                  altText={"hotarari"}
                  titlu={item.folder}
                  docs={item.docs}
                  expanded={true}
                />
              );
            })}
          </List>
        </div>
      </React.Fragment >
    );
  }
}

export default SectiaPenala;
