import React, { Component } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListOfDocs from "./listOfDocs";
import "../css/listStyle.css"

class Anunt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  handleChange = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <React.Fragment>
        {this.props.expanded === true ?
          <div>
            <ListItem style={{padding:0}} button onClick={this.handleChange} >
              <ListItemIcon className="nopadding px-0 mx-0">
                <img src={this.props.icon} alt="Lista_concursuri_si_anunturi"/>
              </ListItemIcon>
              <ListItemText  className="SublistItemText" inset primary={this.props.titlu} />
              {this.state.open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={this.state.open} timeout="auto" unmountOnExit>
              {this.props.text ? <p className="TextBeforeList">{this.props.text}</p> : ""}
              <ListOfDocs list={this.props.docs} button={true} icon1={require("../images/icons/list.png")} component="div"/>
            </Collapse>
          </div>
          :
          <ListOfDocs
            list={this.props.docs}
            button={true}
            icon1={require("../images/icons/law2.png")}
            component="div"
          />
        }
      </React.Fragment>
    );
  }
}

export default Anunt;
