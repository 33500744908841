import React, { Component } from 'react';
import Title from '../../components/Header/HeaderTitlePage';
import Divider from '@mui/material/Divider';
import Anunt from '../../components/anunt';
import axios from 'axios';
import List from '@mui/material/List';

class JurisprudentaSectiaMixta extends Component {
  state = {
    minute: [],
    jurisprudenta: []

  };
  componentDidMount = () => {
    window.scrollTo(0, 0);

    /* Sectia Civila - Minute unificare practica judiciara*/
    axios
      .get(`/getFiles`, {
        params: {
          folderName: "PDFs/Jurisprudenta/Sectia Mixta/Minute unificare practica judiciara"
        }
      })
      .then(res => {

        /* Foreach folder from res, requeat all pdf files  */
        let i = res.data.length - 1;
        for (i; i >= 0; i--) {
          let year = res.data[i];
          axios
            .get(`/getFiles`, {
              params: {
                folderName: "PDFs/Jurisprudenta/Sectia Mixta/Minute unificare practica judiciara/" + year
              }
            })
            .then(res2 => {
              console.log(res2);
              let m = res2.data.length - 1;
              let object2;
              let object3 = [];

              /* Extract data field from pdf filename */
              for (m; m >= 0; m--) {
                let indexStart = res2.data[m].indexOf('-');
                let indexStop = res2.data[m].indexOf('.pdf');
                let dataDoc = res2.data[m].substring(indexStart + 1, indexStop);
                let titluDoc = res2.data[m].substring(0, indexStop);

                object2 = Object.assign({ titlu: titluDoc, data: dataDoc, locatie: "Jurisprudenta/Sectia Mixta/Minute unificare practica judiciara/" + year + "/" + res2.data[m] });
                object3.push(object2);
              }

              /* Create an object[{folder,docs[]}] where:
               * folder  - folder name 
               * docs - list of Pdf files from folder
               *  
               * Add created object to {this.state.folders}
               */
              object3.sort((a, b) => new Date(b.date) - new Date(a.date));
              object3.reverse();
              const object = Object.assign({ folder: year, docs: object3 });
              //var stateCopy = Object.assign({}, this.state);
              //stateCopy.sectii[0].folders = object;
              //this.setState(stateCopy);

              this.setState({ minute: [...this.state.minute, object] });
            })
            .catch(e => {
              console.log("Eroare la deschiderea fișierului", e);
            });
        }
      })
      .catch(e => {
        console.log("Eroare la deschiderea folderului", e);
      });



      /* Sectia Mixta - Jurisprudenta relevanta*/
    axios
    .get(`/getFiles`, {
      params: {
        folderName: "PDFs/Jurisprudenta/Sectia Mixta/Jurisprudenta relevanta"
      }
    })
    .then(res => {

      /* Foreach folder from res, requeat all pdf files  */
      let i = res.data.length - 1;
      for (i; i >= 0; i--) {
        let year = res.data[i];
        axios
          .get(`/getFiles`, {
            params: {
              folderName: "PDFs/Jurisprudenta/Sectia Mixta/Jurisprudenta relevanta/" + year
            }
          })
          .then(res2 => {
            console.log(res2);
            let m = res2.data.length - 1;
            let object2;
            let object3 = [];

            /* Extract data field from pdf filename */
            for (m; m >= 0; m--) {
              let indexStart = res2.data[m].indexOf('-');
              let indexStop = res2.data[m].indexOf('.pdf');
              let dataDoc = res2.data[m].substring(indexStart + 1, indexStop);
              let titluDoc = res2.data[m].substring(0, indexStop);

              object2 = Object.assign({ titlu: titluDoc, data: dataDoc, locatie: "Jurisprudenta/Sectia Mixta/Jurisprudenta relevanta/" + year + "/" + res2.data[m] });
              object3.push(object2);
            }

            /* Create an object[{folder,docs[]}] where:
             * folder  - folder name 
             * docs - list of Pdf files from folder
             *  
             * Add created object to {this.state.folders}
             */
            object3.sort((a, b) => new Date(b.date) - new Date(a.date));
            object3.reverse();
            const object = Object.assign({ folder: year, docs: object3 });
            //var stateCopy = Object.assign({}, this.state);
            //stateCopy.sectii[0].folders = object;
            //this.setState(stateCopy);

            this.setState({ jurisprudenta: [...this.state.jurisprudenta, object] });
          })
          .catch(e => {
            console.log("Eroare la deschiderea fișierului", e);
          });
      }
    })
    .catch(e => {
      console.log("Eroare la deschiderea folderului", e);
    });
  }



  render() {
    return (
      <React.Fragment>
        <Title
          title="Jurisprudență Secția mixtă de contencios și administrativ"
          page="Jurisprudență"
          breadcrumbs={true}
        />

        <div className="my-5 container">
          <h6 className="py-3" variant="body1">
            {" "}
            &emsp;În această pagină au fost incluse hotărâri relevante din practica secţiilor Tribunalului Cluj, destinate atât specialiştilor în drept, cât şi cetăţenilor interesaţi de modul în care au fost rezolvate anumite cazuri în instanţa noastră.
          </h6>
          <Divider />


          {/* Create a List 
            * Add each folder and docs in List using component <Anunt/>
            */}
          {this.state.minute.map((sectie, index) => {
            return (
              <div key={index}>
                <h5><b>Minute unificare practică judiciară</b></h5>
                <List component="nav">

                  <Anunt
                    icon={require("../../images/icons/law2.png")}
                    altText={"jurisprudenta"}
                    titlu={sectie.folder}
                    docs={sectie.docs}
                    expanded={true}
                  />

                </List>
              </div>
            );
          })}


          {this.state.jurisprudenta.map((sectie, index) => {
            return (
              <div key={index}>
                <h5><b>Jurisprudenţă relevantă</b></h5>
                <List component="nav">

                  <Anunt
                    icon={require("../../images/icons/law2.png")}
                    altText={"jurisprudenta"}
                    titlu={sectie.folder}
                    docs={sectie.docs}
                    expanded={true}
                  />

                </List>
              </div>
            );
          })}
        </div>
      </React.Fragment >
    );
  }
}

export default JurisprudentaSectiaMixta;
