import React from 'react';

import SpeedDial from '@mui/lab/SpeedDial';
import SpeedDialAction from '@mui/lab/SpeedDialAction';

import AccessibilityIcon from '@mui/icons-material/Accessibility';
import DecreaseText from '@mui/icons-material/Remove';
import IncreaseText from '@mui/icons-material/Add';
import Restore from '@mui/icons-material/SettingsBackupRestore';
import Contrast from '@mui/icons-material/InvertColors';

import "../css/accesibility.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#CB2D0B"
    },
    secondary: {
      main: "#ffcc80"
    }
  }
});

export default function SpeedDials() {
  const [direction, setDirection] = React.useState('down');
  const [open, setOpen] = React.useState(false);
  const [fontSize, setFont] = React.useState(0);

  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  /* Elementele pe care se schimba fontul*/
  const aClass = document.querySelectorAll("a");
  const pClass = document.querySelectorAll("p");
  const bClass = document.querySelectorAll("b");
  const h5Class = document.querySelectorAll("h5");
  const h4Class = document.querySelectorAll("h4");
  const h6Class = document.querySelectorAll("h6");
  const h2Class = document.querySelectorAll("h2");
  const h1Class = document.querySelectorAll("h1");
  const liClass = document.querySelectorAll("li");
  const strongClass = document.querySelectorAll("strong");
  const spanClass = document.querySelectorAll("span");
  const section = document.querySelectorAll('section');


  const anunturi = document.getElementsByClassName("anunturi");
  const cards = document.getElementsByClassName("single-list-topics-content");
  const listItems = document.getElementsByClassName("MuiListItemText-primary");
  const divListItems = document.getElementsByClassName("MuiList-root");
  const contact = document.getElementsByClassName("card-body");
  
  
 
  const allClasses = [aClass, pClass, h5Class, h6Class, bClass, strongClass, h4Class, h2Class, h1Class, liClass, spanClass];

  const fontSizeClasses = [
    { idx: 1, font: "font-size--x--small" },
    { idx: 2, font: "font-size--small" },
    { idx: 3, font: "font-size--default" },
    { idx: 4, font: "font-size--large" },
    { idx: 5, font: "font-size--extra--large" },
    { idx: 6, font: "font-size--extra--x--large" },
  ]

  const contrastChange = () => {
    [...anunturi].forEach(x => {x.style.color = 'yellow'});
    [...contact].forEach(x => {x.style.backgroundColor = 'black'});

    section.forEach(x => {
      x.setAttribute("class", "contrast")
    });  //adauga noua clasa

    for ( var i = 0; i < cards.length; i++) {
      cards[i].classList.add("contrast");
    }  
    for ( var i = 0; i < divListItems.length; i++) {
      divListItems[i].classList.add("contrast");
    }  
    

    for ( var i = 0; i < listItems.length; i++) {
      listItems[i].classList.add("font-color");
    }  

    allClasses.forEach(x => {
      x.forEach(el => {
        el.classList.add("font-color");  //adauga noua clasa
      });
    })
  }
  const originalFont = () => {

    for ( var i = 0; i < divListItems.length; i++) {
      divListItems[i].classList.remove("contrast");
    }  
    

    for ( var i = 0; i < listItems.length; i++) {
      listItems[i].classList.remove("font-color");
    }  


    [...anunturi].forEach(x => {x.style.color = 'white'});
    [...contact].forEach(x => {x.style.backgroundColor = 'white'});

    for ( var i = 0; i < cards.length; i++) {
      cards[i].classList.remove("contrast");
    } 

    section.forEach(x => {
      x.setAttribute("class", "bg-light")
    });  //adauga noua clasa

    allClasses.forEach(x => {
      x.forEach(el => {
        el.classList.remove("font-color");  //adauga noua clasa
      });
    })
    if (fontSize > 0) {

      fontSizeClasses.map((fontClass) => {
        if (fontClass.idx === fontSize) {   //daca este deja aplicat un fontSize
          allClasses.forEach(x => {
            x.forEach(el => {
              el.classList.remove(fontClass.font);  //sterge clasa existenta din fiecare elem
            })
          })

          allClasses.forEach(x => {
            x.forEach(el => {
              el.classList.add("font-size--default");  //adauga noua clasa
            })
          })
        }
      })
      setFont(3);  //incrementeaza fontSize
    }
  }
  const increaseFont = () => {
    console.log(fontSize);
    if (fontSize < 5 && fontSize > 0) {
      fontSizeClasses.map((fontClass) => {
        if (fontClass.idx === fontSize) {   //daca este deja aplicat un fontSize
          allClasses.forEach(x => {
            x.forEach(el => {
              el.classList.remove(fontClass.font);  //sterge clasa existenta din fiecare elem
            })
          })
          setFont(fontClass.idx + 1);  //incrementeaza fontSize
          allClasses.forEach(x => {
            x.forEach(el => {
              el.classList.add(fontSizeClasses[fontClass.idx].font);  //adauga noua clasa
            })
          })
        }
      })
    }

    if (fontSize === 0) {  //daca nu exista font aplicat
      allClasses.forEach(x => {
        x.forEach(el => {
          el.classList.add("font-size--large"); //aplica clasa large
        })
      })
      setFont(3);   //seteaza fontSize 3
    }

  };
  const decreaseFont = () => {
    console.log(fontSize);
    if (fontSize > 1) {
      fontSizeClasses.map((fontClass) => {
        if (fontClass.idx == fontSize) {   //daca este deja aplicat un fontSize
          allClasses.forEach(x => {
            x.forEach(el => {
              el.classList.remove(fontClass.font);  //sterge clasa existenta din fiecare elem
            })
          })

          setFont(fontClass.idx - 1);   //decrementeaza fontSize
          allClasses.forEach(x => {
            x.forEach(el => {
              el.classList.add(fontSizeClasses[fontClass.idx - 2].font);  //adauga noua clasa
            })
          })
        }
      })
    }

    if (fontSize === 0) {  //daca nu exista font aplicat
      allClasses.forEach(x => {
        x.forEach(el => {
          el.classList.add("font-size--small"); //aplica clasa large
        })
      })
      setFont(2);   //seteaza fontSize 1
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const actions = [
    { icon: <IncreaseText />, name: 'Măriți Fontul', func: increaseFont },
    { icon: <DecreaseText />, name: 'Reduceți Fontul', func: decreaseFont },
    { icon: <Contrast />, name: 'Contrast mare', func: contrastChange },
    { icon: <Restore />, name: 'Resetați', func: originalFont },
  ];

  return (
    <div  sx={{
      transform: 'translateZ(0px)',
      position: 'fixed',
      top: 0,
      right: 10,
      flexGrow: 1,
    }}>
      <ThemeProvider theme={theme}>
        <div  sx={{
          position: 'relative',
          marginTop: (theme) => theme.spacing(3),
          height: 380,
        }}>
          <SpeedDial
            ariaLabel="SpeedDial example"
            sx={{
              position: 'fixed',
              top: 10,
              right: 10,
              transform: 'translateZ(0px)',
              flexGrow: 1,
              '& .MuiSpeedDial-directionUp, & .MuiSpeedDial-directionLeft': {
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(2),
              },
              '& .MuiSpeedDial-directionDown, & .MuiSpeedDial-directionRight': {
                bottom: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(2),
              },
            }}
            hidden={false}
            icon={<AccessibilityIcon fontSize="large" />}
            iconStyles={{ backgroundColor: 'red' }}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}

            direction={direction}
          >
            {actions.map((action) => (
             <SpeedDialAction
             key={action.name}
             icon={action.icon}
             tooltipTitle={action.name}
             onClick={action.func}
             sx={{ fontSize: '18px' }}
           />
            ))}
          </SpeedDial>
        </div>
      </ThemeProvider>
    </div>
  );
}
