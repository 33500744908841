import React, { Component } from 'react';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from '@mui/material/Collapse';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListaPersonal from './EmployeesList';
import "../../css/organizare.css";

class Sectie extends Component {
    state = {
        open: true
    };

    handleChange = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        const { sectia, personal } = this.props;
        return (
            <div>
                <ListItem button onClick={this.handleChange} >
                    <ListItemText primary={sectia} className="personalSectie"/>
                    {/* Select which Expand icon to use  */}
                    {this.state.open ? <ExpandLess /> : <ExpandMore />}

                </ListItem>

                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                    <ListaPersonal
                        personal={personal}
                        expended={this.props.expended}
                    />
                </Collapse>
            </div>
        )
    }
}

export default Sectie;