import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { prezentareInstante } from "../utils/constants";
import "../css/prezentare.css";

class CardInstanta extends React.Component {
  render() {
    const cards = prezentareInstante.map((instanta, index) => {
      return (
        <Grid item md={4} key={index}>
          <Card>
            <CardActionArea>
              {index !== 1 ? (
                <CardMedia
                  style={{ height: 0, paddingTop: "56.25%" }}
                  image={instanta.src}
                  title={instanta.title}
                />
              ) : (
                <p></p>
              )}
              <CardContent className="cardContent">
                <h4 className=" text-center">{instanta.title}</h4>
                <p>
                  &nbsp;&nbsp;
                  {instanta.content}
                </p>
              </CardContent>
            </CardActionArea>

            {index !== 1 ? (
              <CardActions>
                <Button href={instanta.link} size="small" color="primary">
                  Află mai multe
                </Button>
              </CardActions>
            ) : (
              <p></p>
            )}
          </Card>
        </Grid>
      );
    });
    return (
      <Grid container={true} spacing={24} className="py-4" justify="center">
        {cards}
      </Grid>
    );
  }
}

export default CardInstanta;
