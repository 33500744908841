import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
    Nav,
    NavItem,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    NavLink,
    Collapse,
    NavbarToggler,
    Navbar
} from "reactstrap";
import { Box, IconButton, Typography } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import logo from '../../images/mj.png';
import "../../css/headerNavigation.css"; 
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

class NavBarComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeDropdown: null, // Stare unică pentru dropdown activ
            collapsed: true,
        };
    }

    toggleNavbar = () => {
        this.setState({ collapsed: !this.state.collapsed });
    }

    closeNavbar = () => {
        if (!this.state.collapsed) {
            this.toggleNavbar();
        }
    }

    handleMouseEnter = (dropdown) => {
        this.setState({ activeDropdown: dropdown }); // Activează doar dropdown-ul curent
    }

    handleMouseLeave = () => {
        this.setState({ activeDropdown: null }); // Dezactivează dropdown-urile
    }

 
    render() {
        return (
            <header>
                <Box sx={{   
                    padding: '5px 0',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <Box sx={{
                        display: 'flex', 
                        alignItems: 'center', 
                        paddingLeft: '10px', 
                        '@media (max-width: 900px)': {
                            fontSize: '0.8rem !important', // Aplica dimensiunea mai mica pe Box și Typography
                        },
                        '@media (max-width: 600px)': {
                            display: 'none', // Ascunde complet pe ecrane foarte mici
                        }
                    }}>
                        <PhoneIcon fontSize="small" sx={{ marginLeft: '10px', color: '#aa9f89' }} />
                        <Typography variant="body2">
                            <a href="tel:+40264596111" style={{ textDecoration: 'none', color: 'inherit' }}>0264596111</a>
                        </Typography>
                        <LocalPrintshopIcon fontSize="small" sx={{ marginLeft: '10px', color: '#aa9f89' }} />
                        <Typography variant="body2">
                            <a href="fax:+40264595844" style={{ textDecoration: 'none', color: 'inherit' }}>0264595844</a>
                        </Typography>
                        <EmailIcon fontSize="small" sx={{ marginLeft: '10px', color: '#aa9f89' }} />
                        <Typography variant="body2">
                            <a href="mailto:tr-cluj-reg@just.ro" style={{ textDecoration: 'none', color: 'inherit' }}>tr-cluj-reg@just.ro</a>
                        </Typography>
                    </Box>
                </Box>


                {/*<Box sx={{ height: '5px', backgroundColor: '#f5f5f5' }}></Box>*/}
                {/* Secțiunea cu logo și bara de navigare */}
                <AppBar position="static" color="inherit" elevation={0}>
                    <Toolbar sx={{
                        display: 'flex', 
                        justifyContent: 'center', 
                        backgroundColor: '#f5f5f5',
                        paddingLeft: '40px', // Increased padding to the left
                        paddingRight: '40px', // Increased padding to the right
                        '@media (max-width: 900px)': { 
                        paddingLeft: '20px',  // Responsive padding for smaller screens
                        paddingRight: '20px',
                        },
                        '@media (max-width: 600px)': {
                        paddingLeft: '10px',  // Even smaller padding for very small screens
                        paddingRight: '10px',
                        }
                    }}>
                        {/* Logo and Tribunal Name */}
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', paddingRight: '10px' }}>
                        <img src={logo} alt="mjLogo" style={{ width: '50px', marginRight: '10px' }} />
                        <Typography variant="h6">TRIBUNALUL CLUJ</Typography>
                        </Box>

                        {/* Centered Navbar */}
                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
      
                        <Navbar className="text-nowrap navbar-light" expand="lg">
                            <NavbarToggler onClick={this.toggleNavbar} />
                            <Collapse isOpen={!this.state.collapsed} navbar>
                                <Nav className="m-auto" navbar>
                                    <NavItem className="mr-2">
                                        <NavLink className="font-weight-bold nav-link" onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/`}>Acasă</NavLink>
                                    </NavItem>
                                    
                                    {/* Dropdown Despre Instituție */}
                                    <Dropdown nav
                                        isOpen={this.state.activeDropdown === 'instanta'}
                                        onMouseEnter={() => this.handleMouseEnter('instanta')}
                                        onMouseLeave={this.handleMouseLeave}
                                    >
                                        <DropdownToggle nav caret className="font-weight-bold nav-link">Despre Instituţie</DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/prezentare`}>Prezentare</DropdownItem>
                                            <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/competente`}>Competenţe şi Circumscripţie</DropdownItem>
                                            <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/rapoarte`}>Instanţa în cifre</DropdownItem>
                                            <DropdownItem header>Organizare</DropdownItem>
                                            <DropdownItem href={`${process.env.PUBLIC_URL}/conducere`}>Conducere</DropdownItem>
                                            <DropdownItem href={`${process.env.PUBLIC_URL}/organizare`}>Structură organizatorică</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    
                                    {/* Dropdown Informații publice */}
                                    <Dropdown nav
                                        isOpen={this.state.activeDropdown === 'informatii'}
                                        onMouseEnter={() => this.handleMouseEnter('informatii')}
                                        onMouseLeave={this.handleMouseLeave}
                                    >
                                        <DropdownToggle nav caret className="font-weight-bold nav-link">Informaţii publice</DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem href="https://www.curteadeapelcluj.ro/index.php/anunturi/concursuri.html?month=jan&yr=2020">Concursuri</DropdownItem>
                                            <DropdownItem href={`${process.env.PUBLIC_URL}/infoPublice`}>Informaţii de interes public</DropdownItem>
                                            <DropdownItem href={`${process.env.PUBLIC_URL}/declaratii`}>Declaraţii</DropdownItem>
                                            <DropdownItem href={`${process.env.PUBLIC_URL}/comunicate`}>Comunicate de presă</DropdownItem>
                                            <DropdownItem href={`${process.env.PUBLIC_URL}/hotarari`}>Hotărâri de colegiu</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>

                                    {/* Dropdown Instanțe */}
                                    <Dropdown nav
                                        isOpen={this.state.activeDropdown === 'instante'}
                                        onMouseEnter={() => this.handleMouseEnter('instante')}
                                        onMouseLeave={this.handleMouseLeave}
                                    >
                                        <DropdownToggle nav caret className="font-weight-bold nav-link">Instanţe</DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem href="http://portal.just.ro/211/SitePages/acasa_default.aspx?id_inst=211">Judecătoria Cluj-Napoca</DropdownItem>
                                            <DropdownItem href="http://portal.just.ro/219/SitePages/acasa_default.aspx?id_inst=219">Judecătoria Dej</DropdownItem>
                                            <DropdownItem href="http://portal.just.ro/242/SitePages/acasa_default.aspx?id_inst=242">Judecătoria Huedin</DropdownItem>
                                            <DropdownItem href="http://portal.just.ro/235/SitePages/acasa_default.aspx?id_inst=235">Judecătoria Gherla</DropdownItem>
                                            <DropdownItem href="http://portal.just.ro/328/SitePages/acasa_default.aspx?id_inst=328">Judecătoria Turda</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>

                                    {/* Dropdown Jurisprudență */}
                                    <Dropdown nav
                                        isOpen={this.state.activeDropdown === 'jurisprudenta'}
                                        onMouseEnter={() => this.handleMouseEnter('jurisprudenta')}
                                        onMouseLeave={this.handleMouseLeave}
                                    >
                                        <DropdownToggle nav caret className="font-weight-bold nav-link">Jurisprudenţă</DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/jurisprudentaSectiaCivila`}>Secția Civilă</DropdownItem>
                                            <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/jurisprudentaSectiaPenala`}>Secția Penală</DropdownItem>
                                            <DropdownItem onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/jurisprudentaSectiaMixta`}>Secția mixtă de contencios și administrativ</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>

                                    <NavItem className="mr-2">
                                        <NavLink className="font-weight-bold nav-link" onClick={this.closeNavbar} href="https://www.curteadeapelcluj.ro/index.php/dosare/info-dosar.html">Info-Dosar</NavLink>
                                    </NavItem>
                                    <NavItem className="mr-2">
                                        <NavLink className="font-weight-bold nav-link" onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/contact`}>Contact</NavLink>
                                    </NavItem>
                                    
                                    <NavItem className="mr-2 nav-item-custom">
                                        <NavLink className="nav-link-custom2" onClick={this.closeNavbar} href={`${process.env.PUBLIC_URL}/salaLive`}>
                                            Sali Live
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Collapse>
                        </Navbar>
                        </Box>
                    </Toolbar>
                </AppBar>
        </header>
        );
    }
}

export default NavBarComp;